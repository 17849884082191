<template>
  <b-card-code
      title="Új hozzáadása"
  >
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col md="6">
            <b-form-group
                label="Cím"
            >
              <validation-provider
                  #default="{ errors }"
                  name="cím"
                  rules="required"
                  vid="title"
              >
                <b-form-input
                    v-model="formData.title"
                    :state="errors.length > 0 ? false:null"
                    placeholder="cím"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
                label="Státusz"
            >
              <validation-provider
                  #default="{ errors }"
                  name="státusz"
                  rules="required"
                  vid="status"
              >
                <v-select
                    v-model="formData.status"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    :options="statusOptions"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
                label="Címke"
            >
              <validation-provider
                  #default="{ errors }"
                  name="címke"
                  vid="tags"
              >
                <v-select
                    v-model="formData.tags"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="title"
                    multiple
                    taggable
                    push-tags
                    :options="tagOptions"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
                label="Lead"
            >
              <validation-provider
                  #default="{ errors }"
                  name="lead"
                  rules="required"
                  vid="lead"
              >
                <b-form-textarea
                    v-model="formData.lead"
                    rows="10"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
                label="Szöveg"
            >
              <validation-provider
                  #default="{ errors }"
                  name="szöveg"
                  rules="required"
                  vid="article"
              >
                <b-link
                    @click="$bvModal.show('bv-modal-article-help')"
                >
                  <small>* "Speciális jelölések" súgó</small>
                </b-link>
                <b-form-textarea
                    v-model="formData.article"
                    rows="20"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
                label="Megjelenés kezdete"
            >
              <validation-provider
                  #default="{ errors }"
                  name="megjelenés kezdete"
                  rules=""
                  vid="visible_from"
              >
                <flat-pickr
                    v-model="formData.visible_from"
                    class="form-control"
                    :config="flatPickRConfig"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
                label="Megjelenés vége"
            >
              <validation-provider
                  #default="{ errors }"
                  name="megjelenés vége"
                  rules=""
                  vid="visible_to"
              >
                <flat-pickr
                    v-model="formData.visible_to"
                    class="form-control"
                    :config="flatPickRConfig"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <validation-provider
                #default="{ errors }"
                name="rögzített"
                vid="sticky"
            >
              <b-form-checkbox
                  v-model="formData.sticky"
                  :state="errors.length > 0 ? false:null"
              >
                Rögzített írás?
              </b-form-checkbox>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <!-- submit button -->
          <b-col class="mt-1">
            <b-button
                variant="primary"
                type="submit"
                class="float-right"
                @click.prevent="submitForm"
            >
              Mentés
            </b-button>
            <cancel-and-g-o-t-o-previous-page class="mr-2"/>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <help-modal/>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormCheckbox,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BFormTextarea,
} from 'bootstrap-vue'
import {
  required,
} from '@validations'
import vSelect from 'vue-select'
import labels from '@/libs/datepicker/labels'
import helpModal from '@/views/pages/_components/_modal/article/help.vue'
import cancelAndGOTOPreviousPage from '@/views/pages/_components/_helper/cancelAndGOTOPreviousPage'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css';
import {Hungarian} from 'flatpickr/dist/l10n/hu.js'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    BLink,
    BFormTextarea,
    flatPickr,
    vSelect,
    helpModal,
    cancelAndGOTOPreviousPage,
  },
  data() {
    return {
      role: 'article',
      formData: {
        title: '',
        tags: [],
        lead: '',
        article: '',
        visible_from: '',
        visible_to: '',
        status: '',
        sticky: '',
      },
      flatPickRConfig: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
        locale: Hungarian,
        allowInput: true
      },
      tagOptions: [],
      statusOptions: [],
      labels,
      required,
    }
  },
  created() {
    this.$store.dispatch('fetchAllTagsForBootstrap').then(response => {
      this.tagOptions = response
    })

    this.$store.dispatch('articleStatuses').then(response => {
      this.statusOptions = response.data.values
      this.formData.status = this.statusOptions[0]
    })
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let fd = {...this.formData}
          fd.status = this.formData.status.value

          this.$store.dispatch('storeArticle', fd).then(response => {
            this.$helpers.showSuccessToast()

            this.$acl.canListPermission(this.role)
                ? this.$router.push({name: 'modify-article', params: {id: response.data.entity.id}})
                : this.$router.go(-1)
          }).catch(error => {
            if (error.response.status === 422) {
              this.$refs.simpleRules.setErrors(error.response.data.errors)
            } else {
              this.$helpers.showErrorToast(error.toString())
            }
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
